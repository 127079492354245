<template>
  <a-upload
    name="file"
    :multiple="mult"
    :action="route"
    :headers="headers"
    :data="data"
    :file-list="fileList"
    @change="handleChange"
  >
    <a-button> <a-icon type="upload" />选择文件 </a-button>
  </a-upload>
</template>

<script>
export default {
  props: ["mult", "folder", "oldFiles", "allow"],
  data() {
    return {
      route: this.API + "/file/uploadFiles",
      headers: {
        token: "",
      },
      data: {},
      fileList: [],
    };
  },
  watch: {
    oldFiles() {
      if (this.oldFiles.length > 0) {
        this.renderFiles();
      }
    },
  },
  created() {
    this.headers.token = this.$store.state.token;
    this.data.folder = this.folder || "contractModal";
    // console.log(this.oldFiles, 88888);
    this.renderFiles();
  },
  methods: {
    renderFiles() {
      if (this.oldFiles) {
        if (!this.mult) {
          this.fileList.push({
            uid: this.oldFiles.file_path,
            name: this.oldFiles.file_name,
            status: "done",
            url: "",
            response: {
              status: 1,
              fileObj: {
                base: this.oldFiles.file_name,
              },
              file_path: this.oldFiles.file_path,
            },
          });
        } else {
          for (let item of this.oldFiles) {
            this.fileList.push({
              uid: item.file_path,
              name: item.file_name,
              status: "done",
              url: "",
              response: {
                status: 1,
                fileObj: {
                  base: item.file_name,
                },
                file_path: item.file_path,
              },
            });
          }
        }
      }
    },
    handleChange(info) {
      if (this.allow) {
        // 获取上传文件的后缀名
        const arr = info.file.name.split(".");
        const ext = arr[arr.length - 1];

        if(!this.allow.includes(ext)) return this.$message.error('不支持的文件类型')
      }

      let files = [];

      if (info.fileList.length) {
        let fileList = [...info.fileList];

        if (!this.mult) fileList = [info.fileList[info.fileList.length - 1]];

        // 2. read from response and show file link
        fileList = fileList.map((file) => {
          if (file && file.response) {
            // Component will show file.url as link
            file.url = file.response.url;
          }
          return file;
        });
        this.fileList = fileList;
        // console.log(this.fileList, 1234567);

        for (let item of this.fileList) {
          if (item.status == "done" && item.response.status == 1) {
            if (this.mult) {
              files.push({
                file_path: item.response.fileList[0].file_path,
                file_name: item.response.fileList[0].fileObj.base,
                fileObj: item.response.fileList[0].fileObj,
              });
            } else {
              files = [
                {
                  file_path: item.response.file_path,
                  file_name: item.response.fileObj.base,
                  fileObj: item.response.fileObj,
                },
              ];
            }
          }
        }
      } else {
        this.fileList = [];
      }

      if (["done", "removed"].includes(info.file.status)) {
        console.log(files, info.file.status, "--------- 上传成功的文件 ---------");
        
        if (this.mult) {
          this.$emit("callback", files);
        } else {
          if (files.length == 0) {
            this.$emit("callback", {
              file_name: "",
              file_path: "",
              fileObj: {},
            });
          } else {
            this.$emit("callback", files[0]);
          }
        }
      }
    },
  },
};
</script>