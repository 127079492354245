<template>
  <div class="component-wrap">
    <a-form class="form sec" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <!-- <a-row>
        <a-col :span="12"> -->
      <a-form-item label="姓名/昵称">
        {{ form.name }}
      </a-form-item>

      <a-form-item label="手机号">
        {{ form.phone }}
      </a-form-item>

      <a-form-item label="关键词">
        {{ form.keywords }}
      </a-form-item>

      <a-form-item label="灯光">
        {{ form.dengguang }}
      </a-form-item>

      <a-form-item label="色调">
        {{ form.sediao }}
      </a-form-item>

      <a-form-item label="精度">
        {{ form.jingdu }}
      </a-form-item>

      <a-form-item label="艺术风格">
        {{ form.yishufengge }}
      </a-form-item>

      <a-form-item label="提示词结果">
        {{ tishici }}
      </a-form-item>

      <a-form-item label="素材模板">
        {{ form.tpl_title }}
      </a-form-item>

      <a-form-item label="素材图">
        <div style="display: flex;" class="img-item">
          <img v-for="(item, key) in form.tpl_imgs" :key="key" style="height: 80px; margin-left: 10px;" :src="API + item"
            alt="">
        </div>
      </a-form-item>

      <a-form-item label="提交时间">
        {{ form.created_at }}
      </a-form-item>

      <a-form-item label="生成图">
        <div style="display: flex;" class="img-item">
          <img v-for="(item, key) in form.result_imgs" :key="key" style="height: 80px; margin-left: 10px;"
            :src="API + item" alt="">
        </div>
      </a-form-item>
    </a-form>

    <!-- 提交按钮 -->
    <div class="buttons">
      <a-button type="primary" @click="show_upload = true">上传</a-button>
      <a-button type="" @click="goBack">返回</a-button>
    </div>

    <Upload v-if="show_upload" :id="id" :mult="true" @close="closeUpload" />
  </div>
</template>

<style lang='scss' scoped></style>

<script>
import moment from "moment";
import Upload from './upload.vue'
export default {
  components: { Upload },
  data() {
    return {
      id: null,
      form: {
      },
      show_upload: false,
      tishici: ''
    };
  },
  async created() {
    this.id = this.$route.query.id || null;
    if (!this.id) {
      this.$message.error("数据出错了,请刷新重试~~");
      return;
    }
    this.getDetail()
  },
  methods: {
    async getDetail() {
      const r = await this.$axios.get("/form/getDetail?id=" + this.id)
      // console.log(r.detail, 2828828);
      if (r.status == 1) {
        this.form = r.detail;

        this.tishici = ''
        let imgs = []
        for (let i of this.form.tplImgs) {
          imgs.push('https://ai-api.wxorder.com' + i.file_path.replace(' ', '%20'))
        }

        imgs = imgs.join(' ')

        this.tishici = imgs + ' ' + this.form.tishicijieguo
        console.log(this.tishici, 888888);
      }
      // if(r.start_at){
      //   r.start_at = moment(r.start_at).format('YYYY-MM-DD HH:mm:ss')
      // }
      // this.form = {
      //   id: r.id,
      //   file_list: [],
      //   name: r.name,
      //   phone: r.phone,
      //   created_at: r.created_at,
      //   keywords: r.keywords,
      // }
      // if(r.file_list) {
      //   this.form.file_list = JSON.parse(r.file_list)
      // }
    },
    async goBack() {
      this.$router.push('/form/list')
    },
    closeUpload(data) {
      this.show_upload = false
      if (data) {
        this.getDetail()
      }
    }
  },
};
</script>