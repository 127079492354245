<template>
  <div class="flex-page">
    <a-form
      class="form"
      :label-col="formItemLayout.labelCol"
      :wrapper-col="formItemLayout.wrapperCol"
    >
      <a-form-item label="素材名称">
        <a-input type="text" style="width: 600px" v-model="form.title"></a-input>
      </a-form-item>

      <a-form-item label="素材缩略图">
        <UploadFile
          @callback="uploaded"
          :mult="true"
          folder="auction"
        />
        <div v-if="thumbList">
          <img
            v-for="(item, key) in thumbList"
            :key="key"
            style="max-height: 60px; margin-left: 10px"
            :src="API + item.file_path"
            alt=""
          />
        </div>
      </a-form-item>

      <a-form-item label="素材原图">
        <UploadFile
          @callback="uploaded2"
          :mult="true"
          folder="auction"
        />
        <div v-if="imgList">
          <img
            v-for="(item, key) in imgList"
            :key="key"
            style="max-height: 60px; margin-left: 10px"
            :src="API + item.file_path"
            alt=""
          />
        </div>
      </a-form-item>
    </a-form>
    <div class="buttons">
      <a-button type="primary" @click="sub">提交修改</a-button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import UploadFile from "../../components/uploadFile.vue";

export default {
  components: { UploadFile },
  data() {
    return {
      formItemLayout: {
        labelCol: {
          span: 3,
        },
        wrapperCol: {
          span: 20,
        },
      },
      thumbList: [],
      imgList: [],
      form: {},
    };
  },
  async created() {

    const id = this.$route.query.id;
    const r = await this.$axios.get("/tpl/getInfo?id=" + id);
    // console.log(r);
    if (r.status == 1) {
      this.form = r.info;
      this.imgList = r.info.imgs;
      this.thumbList = r.info.thumbs;
    }
  },
  methods: {
    moment,
    uploaded(file) {
      this.thumbList = file;
    },
    uploaded2(file) {
      this.imgList = file;
    },
    async sub() {
      const that = this;
      if (!this.form.title) return this.$message.error("请填写素材名称");
      // if (!this.form.thumb) return this.$message.error('请上传拍品封面');

      const form = JSON.parse(JSON.stringify(this.form));
      form.thumbs = this.thumbList;
      form.imgs = this.imgList;
      const r = await that.$axios.post("/tpl/update", form);

      if (r.status == 1) {
        this.$router.back();
      }
    }
  },
};
</script>