import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import axios from 'axios'
import store from '../store/index'

const routes = [
  // =================== 首页 ===================
  {
    path: '/',
    name: 'index',
    meta: {
      title: '首页'
    },
    component: require('@/pages/index').default
  },

  // =================== 登录 ===================
  {
    path: '/user/login',
    name: 'login',
    meta: {
      layout: 'simple'
    },
    component: require('@/pages/user/login').default
  },
  {
    path: '/user/updatePsw',
    name: 'updatePsw',
    meta: {
      title: '修改密码'
    },
    component: require('@/pages/user/updatePsw').default
  },

  // =================== 场次管理 ===================

  {
    path: '/form/list',
    name: 'formList',
    meta: {
      title: '表单列表'
    },
    component: require('@/pages/form/list').default
  },
  {
    path: '/form/info',
    name: 'formDetail',
    meta: {
      title: '表单详情'
    },
    component: require('@/pages/form/info').default
  },

  // =================== 素材管理 ===================
  {
    path: '/tpl/list',
    name: 'tplList',
    meta: {
      title: '素材列表'
    },
    component: require('@/pages/tpl/list').default
  },
  {
    path: '/tpl/add',
    name: 'tplAdd',
    meta: {
      title: '添加素材'
    },
    component: require('@/pages/tpl/add').default
  },

  {
    path: '/tpl/info',
    name: 'tplInfo',
    meta: {
      title: '素材详情'
    },
    component: require('@/pages/tpl/info').default
  },
  {
    path: '/tpl/update',
    name: 'tplUpdate',
    meta: {
      title: '更新素材'
    },
    component: require('@/pages/tpl/update').default
  }
]

const router = new VueRouter({
  routes
})


router.beforeEach(async (to, from, next) => {
  let navs = store.state.navs;
  if (navs.length == 0 && to.meta.layout != 'simple') {
    const r = await axios.get("/user/getNavs");
    if (r.status == 1) {
      // 保存所有导航数组到store
      navs = r.navs;
      store.commit("setNavs", navs);

      // ----------------- 生成一级菜单 -----------------
      const menus = []; //最终生成的一二级菜单
      navs.forEach((nav) => {
        if (nav.class == 1) menus.push(nav);
      });

      // ----------------- 生成二级菜单 -----------------
      for (let menu of menus) {
        menu.children = [];
        navs.forEach((nav) => {
          if (nav.father == menu.id && nav.show == 1) menu.children.push(nav);
        });
        if (menu.children.length == 0) delete menu.children;
      }
      // 保存菜单到store
      store.commit("setMenus", menus);
    }
  }

  // console.log(navs);
  let selectedKeys = []; //当前选中菜单
  let openKeys = []; //当前展开菜单
  const breadcrumbs = []; //面包屑

  const now_path = to.path;
  let nowNav; //当前正在运算的nav

  for (let nav of navs) {
    if (nav.path == now_path || nav.route == now_path) {
      nowNav = nav;
      break
    }
  }
  // console.log(nowNav, 88822);
  if (!nowNav) return next()

  const getFather = (id) => {
    for (let nav of navs) {
      if (nav.id == id) return nav
    }
  }

  // 根据father层层查找
  const hello = (nowNav) => {
    breadcrumbs.unshift(nowNav.title);
    if (nowNav.class == 2 && nowNav.father) selectedKeys.unshift(nowNav.id);
    if (nowNav.class == 1 && !nowNav.father) openKeys.unshift(nowNav.id);

    if (nowNav.father !== 0) {
      const father = getFather(nowNav.father);
      hello(father)
    }
  }

  if (nowNav.class == 1) {
    selectedKeys.unshift(nowNav.id);
    breadcrumbs.unshift(nowNav.title);
  }
  else {
    hello(nowNav)
  }

  store.commit('setActiveMenu', {
    selectedKeys,
    openKeys,
    breadcrumbs
  })

  next()
})

// 避免跳转到当前网址的报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


export default router
