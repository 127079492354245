<template>
  <div>
    
  </div>
</template>

<style lang='scss' scoped>
</style>

<script>
export default {
  data() {
    return {
      
    }
  },
  beforeCreate () {},
  created () {
    this.$router.push('/form/list')
  },
  mounted () {},
  methods: {}
}
</script>