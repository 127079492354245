<template>
  <div class="flex-page">
    <a-form
      class="form"
      :label-col="formItemLayout.labelCol"
      :wrapper-col="formItemLayout.wrapperCol"
    >
      <a-form-item label="素材标题">{{info.title}}</a-form-item>

      <a-form-item label="素材缩略图">
        <img v-for="(item, key) in info.thumbs" :key="key" style="height: 60px; margin-left: 10px;" :src="API + item.file_path" @click="open(API + item.file_path)" alt="">
      </a-form-item>

      <a-form-item label="素材原图">
        <img v-for="(item, key) in info.imgs" :key="key" style="height: 60px; margin-left: 10px;" :src="API + item.file_path" @click="open(API + item.file_path)" alt="">
      </a-form-item>

    </a-form>
    <div class="buttons">
      <a-button type="primary" @click="update">编辑</a-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form {
  padding: 40px;
}
.sec {
  margin: 15px;
}
img {
  max-width: 100%;
}
</style>

<script>
export default {
  data() {
    return {
      formItemLayout: {
        labelCol: {
          span: 3,
        },
        wrapperCol: {
          span: 20,
        },
      },
      info: {},
    };
  },
  async created() {
    const id = this.$route.query.id;
    const r = await this.$axios.get("/tpl/getInfo?id=" + id);
    if (r.status == 1) {
      this.info = r.info;
      console.log(this.info, 888);
    }
  },
  methods: {
    update() {
      this.$router.push(`/tpl/update?id=${this.info.id}`);
    },
    open (path) {
      window.open(path)
    }
  },
};
</script>