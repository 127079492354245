<template>
  <div class="page">
    <a-form class="ctrl" layout="inline">
      
      <a-form-item label="标题">
        <a-input style="width: 160px;" v-model="options.title" placeholder="请输入标题"></a-input>
      </a-form-item>

      <a-form-item>
        <a-button type="primary" @click="getList">搜索</a-button>
        <a-button type="default" @click="clear" style="margin: 0 10px">清除</a-button>
        <!-- <a-button type="primary" @click="exportData">导出</a-button> -->
      </a-form-item>
    </a-form>

    <div class="statistics">共{{ amount }}条</div>

    <a-table class="sec" size="small" :rowKey="(record) => record.id" bordered :columns="columns" :data-source="list"
      :pagination="false">
      <template slot="imgs" slot-scope="text, record">
        {{ record.imgs.length }}
      </template>
      <template slot="action" slot-scope="text, record">
        <router-link :to="'/tpl/info?id=' + record.id">详情</router-link>
        <a-divider type="vertical" />
        <router-link :to="'/tpl/update?id=' + record.id">编辑</router-link>
        <a-divider type="vertical" />
        <a-popconfirm :title="record.status == 1 ? '确定要删除吗？' : '确定要恢复吗？'" ok-text="确定" cancel-text="取消"
          @confirm="updateStatus(record.id, (record.status + 1) % 2), 'status'">
          <a href="javascript:;">{{ record.status == 1 ? "删除" : "恢复" }}</a>
        </a-popconfirm>

        <a-divider type="vertical" />
        <a-popconfirm :title="record.checked  == 1 ? '确定取消选中吗？' : '确定选中吗？'" ok-text="确定" cancel-text="取消"
          @confirm="updateStatus(record.id, record.checked  == 1 ? 0 : 1, 'checked' )">
          <a href="javascript:;">{{ record.checked == 1 ? "取消选中" : "选中" }}</a>
        </a-popconfirm>


      </template>
    </a-table>

    <a-pagination class="pagination" showQuickJumper v-model="options.now_page" :defaultPageSize="50" :total="amount"
      @change="getList" />

  </div>
</template>

<style lang='scss' scoped>
</style>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
  },
  
  {
    title: "标题",
    dataIndex: "title",
  },

  {
    title: "图片",
    scopedSlots: {
      customRender: "imgs",
    },
  },

  {
    title: "操作",
    scopedSlots: {
      customRender: "action",
    },
  },
];

export default {
  data() {
    return {
      columns,
      list: [],
      amount: 0,
      options: {
        title: '',
        now_page: 1
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    clear() {
      this.options = {
        title: '',
        now_page: 1
      };
      this.getList()
    },
    async getList() {
      const r = await this.$axios.get("/tpl/getList", {
        params: this.options,
      });

      if (r.status != 1) return;
console.log(r, 82828);
      this.list = r.list;
      this.amount = r.amount;
    },

    async updateStatus(id, status, type = 'status') {
      const r = await this.$axios.post("/tpl/updateStatus", {
        id,
        status,
        type
      });

      if (r.status == 1) this.getList();
    },
  },
};
</script>