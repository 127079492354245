<template>
  <div class="page">
    <!-- 检索条件 类型 客户 电话 车牌号 创建时间段 服务时间段 状态 -->
    <a-form class="ctrl" layout="inline">
      <a-form-item label="姓名">
        <a-input style="width: 160px" placeholder="姓名" v-model="options.name" />
      </a-form-item>

      <a-form-item label="手机号">
        <a-input style="width: 160px" placeholder="手机号" v-model="options.phone" />
      </a-form-item>

      <a-form-item label="创建时间">
        <a-date-picker v-model="options.create_start" /> -
        <a-date-picker v-model="options.create_end" />
      </a-form-item>


      <a-form-item>
        <a-button type="primary" @click="getList">搜索</a-button>
        <a-button type="default" @click="clear" style="margin: 0 10px">清除</a-button>
        <!-- <a-button type="primary" @click="exportData">导出</a-button> -->
      </a-form-item>
    </a-form>

    <div class="statistics">共{{ pages.amount }}条</div>

    <a-table class="sec" size="small" :rowKey="(record) => record.id" bordered :columns="columns" :data-source="list"
      :pagination="false">

      <template slot="action" slot-scope="text, record">
        <router-link :to="'/form/info?id=' + record.id">
          <a-button style="margin-left: 5px" size="small" type="">
            查看
          </a-button>
        </router-link>
      </template>

      <template slot="upload" slot-scope="text, record">
        <a-button style="margin-left: 5px" size="small" type="primary" @click="upload(record.id)">
          上传
        </a-button>
        <span style="margin-left: 5px" v-if="record.result_imgs">已上传</span>
      </template>

      <template slot="del" slot-scope="text, record">
        <a-popconfirm title="确定删除吗" ok-text="确定" cancel-text="取消"
          @confirm="updateStatus(record.id, 0)">
          <a-button size="small">删除</a-button>
        </a-popconfirm>
      </template>
    </a-table>

    <a-pagination class="pagination" showQuickJumper v-model="pages.now_page" :defaultPageSize="50" :total="pages.amount"
      @change="getList" />

    <Upload v-if="uploadModal.show" :id="uploadModal.id" @close="closeUpload" :mult="true" />
  </div>
</template>

<style lang='scss' scoped></style>

<script>
import moment from "moment";
import Upload from './upload.vue'
const columns = [
  {
    title: "#",
    dataIndex: "id",
  },
  {
    title: "姓名",
    dataIndex: "name",
  },
  {
    title: "手机号",
    dataIndex: "phone",
  },
  {
    title: "关键词",
    dataIndex: "keywords",
  },
  // {
  //   title: "提词器",
  //   dataIndex: "tishicijieguo",
  // },
  {
    title: "提交时间",
    dataIndex: "created_at",
  },
  {
    title: "操作",
    scopedSlots: {
      customRender: "action",
    },
  },
  {
    title: "上传",
    scopedSlots: {
      customRender: "upload",
    },
  },
  {
    title: "删除",
    scopedSlots: {
      customRender: "del",
    },
  },


];
export default {
  components: { Upload },
  data() {
    return {
      options: {
        name: "",
        phone: null,
        create_start: null,
        create_end: null
      },
      columns,
      list: [],
      pages: {
        now_page: 1,
        amount: 0,
      },
      uploadModal: {
        id: null,
        show: false
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    upload(id) {
      this.uploadModal = {
        show: true,
        id
      }
    },
    closeUpload() {
      this.uploadModal = {
        show: false,
        id: null
      }
      this.getList()
    },
    clear() {
      this.options = {
        name: "",
        phone: null,
        create_start: null,
        create_end: null
      };
      this.getList();
    },
    async updateStatus(id, status) {
      const r = await this.$axios.post('/form/updateStatus', {
        id, status
      })
      if(r.status == 1) this.getList()
    } ,
    async getList() {
      const options = { ...this.options };

      if (options.create_start)
        options.create_start = moment(options.create_start)
          .startOf("d")
          .valueOf();
      if (options.create_end)
        options.create_end = moment(options.create_end).endOf("d").valueOf();

      options.now_page = this.pages.now_page;

      const r = await this.$axios.get("/form/getList", {
        params: options,
      });

      if (r.status == 1) {
        this.list = r.list;
        this.pages.amount = r.amount;
      }
    }
  },
};
</script>