<template>
  <div class="flex-page">
    <a-form
      class="form"
      :label-col="formItemLayout.labelCol"
      :wrapper-col="formItemLayout.wrapperCol"
    >
      
      <a-form-item label="素材标题">
        <a-input type="text" style="width: 600px" v-model="form.title"></a-input>
      </a-form-item>

      <a-form-item label="素材缩略图">
        <UploadFile @callback="uploaded3" :mult="true" folder="auction" />
        <div v-if="form.imgs">
          <img
            v-for="(item, key) in form.thumbs"
            :key="key"
            style="max-height: 60px; margin-left: 10px"
            :src="API + item.file_path"
            alt=""
          />
        </div>
      </a-form-item>

      <a-form-item label="素材原图">
        <UploadFile @callback="uploaded2" :mult="true" folder="auction" />
        <div v-if="form.imgs">
          <img
            v-for="(item, key) in form.imgs"
            :key="key"
            style="max-height: 60px; margin-left: 10px"
            :src="API + item.file_path"
            alt=""
          />
        </div>
      </a-form-item>
    </a-form>
    <div class="buttons">
      <a-button type="primary" @click="sub">添加素材</a-button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import UploadFile from "../../components/uploadFile.vue";
import Editor from "../../components/editor.vue";

export default {
  components: { UploadFile, Editor },
  data() {
    return {
      formItemLayout: {
        labelCol: {
          span: 3,
        },
        wrapperCol: {
          span: 20,
        },
      },
      form: {
        title: '',
        thumbs: [],
        imgs: []
      },
    };
  },
  async created() {
    
  },
  methods: {
    moment,
    uploaded2(file) {
      this.form.imgs = file;
      console.log('this.form.imgs---------')
      console.log(this.form.imgs)
    },

    uploaded3(file) {
      this.form.thumbs = file;
      console.log('this.form.thumbs---------')
      console.log(this.form.thumbs)
    },

    async sub() {
      if (!this.form.title) return this.$message.error("请填写素材名称");
      if (!this.form.imgs.length) return this.$message.error('请上传图片');
      const r = await this.$axios.post("/tpl/create", this.form);

      if (r.status == 1) {
        this.$router.push('/tpl/list');
      }
    },
  },
};
</script>